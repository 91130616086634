import React from 'react';
import PageHeader from '../components/content/PageHeader';
import Layout from '../modules/Layout';
import Text from '../components/content/Text';
import ContentIconTeaser from '../components/content/IconTeaser';
import ContentTeaser from '../components/content/ContentTeaser';
import { beruf, haftpflicht, KpiItems, risiko, sliderItems, unfall } from '.';
import ContentSlider from '../components/content/ContentSlider';
import Kpi from '../components/content/Kpi';
import FamilyCheck from '../components/content/FamilyCheck';

const iconTeserItems = [
  {
    text: 'Schützt Ihr Hab und Gut bei Einbruchdiebstahl-, Leitungswasser-, Feuerschäden etc.',
  },
  {
    text: 'Gilt auch bei Überschwemmungen durch Starkregen einschl. Rückstau',
  },
  {
    text: 'Schützt auch bei Diebstahl aus verschlossenem Auto (inkl. Dachgepäckbox u. Motorradkoffer) bis 3.000 €',
  },
];

const contentTeaserItems = [beruf, risiko, haftpflicht, unfall];

const Hausrat = () => (
  <Layout
    pageName="IIIᐅ Familienversicherung | VGH"
    description="VGH Versicherungscheck online ✅ Welche Versicherungen brauchen Familien? ✅ Wie schützen Sie Ihre Familie am besten? ᐅ Jetzt informieren! "
  >
    <PageHeader
      desktopImage="headerHausrat"
      mobileImage="headerHausrat_mob"
      badge1Line1="man kalt erwischt wird,"
      badge2Line1="kann man mit uns"
      badge2Line2="ganz cool bleiben"
    />
    <Text
      textAboveHeadline="Hausratversicherung"
      headline="Falls Ihr Zuhause mal nasse Füße kriegt"
      text="<p>Egal, ob Villa Kunterbunt auf dem Land oder Wohnung in der City: Ihr Zuhause ist der schönste Ort der Welt. Vor allem, weil Sie viel Zeit und Geld investiert haben, um alles toll einzurichten. Sichern Sie Ihren Hausrat deshalb besser ab – nur für den Fall, dass alles nach einem Wasserschaden oder so ersetzt werden muss.</p>"
    />
    <ContentIconTeaser
      items={iconTeserItems}
      btnTarget="https://www.vgh.de/content/privat/versicherungen/bauen-und-wohnen/abschluss/hausrat.html#"
      btnText="Jetzt berechnen"
    />
    <FamilyCheck
      headline="Wie viel Sicherheit braucht Ihre Familie?"
      text="Klar wissen Sie, dass Sicherheit absolut wichtig ist und Versicherungen
      sein müssen. Aber welche? Und vor allem, welche passen wirklich zu Ihnen
      und Ihrer Lebenssituation? Klicken Sie sich einfach kurz durch unseren Familien-Check. Wir zeigen Ihnen dann im
      Anschluss direkt die Versicherungen, die Sie auf jeden Fall haben sollten."
    />
    <ContentTeaser
      headline="Unsere stärksten Versicherungen für Sie und die Kids"
      text="Neben unserer Hausratversicherung sind auch diese vier Versicherungen wichtig, um gut abgesichert in Ihre Familienzukunft zu starten. Stiftung Warentest empfiehlt unsere stärksten Versicherungen für junge Familien ebenfalls."
      items={contentTeaserItems}
    />
    <Text
      headline="Wenn Sie für Ihre Familie auf Nummer sicher gehen wollen"
      text="Noch mehr Versicherungen? Reichen nicht zwei oder drei? Das entscheiden natürlich Sie und hängt zudem von Ihrer Lebenssituation ab. Aber schauen Sie einfach mal durch. Wir haben für alle Ansprüche die passende Versicherung – und passen uns auch Ihren individuellen Wünschen an."
    />
    <ContentSlider items={sliderItems} />
    <Kpi
      headline="Warum VGH?"
      kpiItems={KpiItems}
      btnTarget="https://www.vgh.de/content/privat/service/betreuersuche/"
      btnText="Berater finden"
    />
  </Layout>
);

export default Hausrat;
